import { useContext, useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { useHistory } from 'react-router-dom';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { PayerType } from '@merchstores/shared/components/MerchStore';

import { ICartPageProps } from '@merchstores/frontend/layouts/CartPage/CartPageProps';

import { Card } from '@merchstores/shared/elements/Card';

import { Loading } from '@merchstores/shared/components/Loading';

import { CartAdminPayerLabel } from '@merchstores/frontend/components/Cart';
import {
  CartProductList,
  CartSummary,
} from '@merchstores/frontend/components/Cart';
import {
  CartCheckoutCTA,
  CartKeepShoppingCTA,
} from '@merchstores/frontend/components/Cart';

import { OrderProcessingDate } from '@merchstores/frontend/components/Order';

import {
  ICart,
  IMerchStore,
  IStoreState,
} from '@merchstores/frontend/reduxTypes';
import {
  handleClearCart,
  handleFetchCartProducts,
} from '@merchstores/frontend/components/Cart/CartActions';
import { CartContext } from '@merchstores/frontend/context/Cart';
import { Helmet } from 'react-helmet';

export const CartPage: React.FC<ICartPageProps> = (props: ICartPageProps) => {
  const showLoading = props.loading !== false;
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [isEmpty, setEmpty] = useState(true);

  const merchstore: IMerchStore = useSelector(
    (state: IStoreState) => state.merchstore,
    shallowEqual
  );
  const isMerchStoreClosed = merchstore.status === 'closed';
  const cart: ICart = useSelector(
    (state: IStoreState) => state.cart,
    shallowEqual
  );

  const {
    state: { member },
  } = useContext(CartContext);

  const giftCardsEnabled = merchstore.giftCardsStatus?.enabled;
  const isAutoProcessingEnabled = merchstore.autoProcessOrders;
  const payerType = merchstore.payerType;
  const isIndividualPayer = payerType === PayerType.INDIVIDUAL;
  const isAdminPayer = payerType === PayerType.ADMIN;

  const clearCartHook = () => {
    handleClearCart(dispatch, history);
  };

  const reloadCartProductsHook = () => {
    handleFetchCartProducts(dispatch, cart).then(() => {
      setLoading(false);
    });
  };

  const emptyPageHook = () => {
    setEmpty(cart.itemCount === 0);
  };

  useEffect(clearCartHook, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(reloadCartProductsHook, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(emptyPageHook, [cart]);

  if (showLoading && isLoading) {
    return <Loading isLoading={isLoading as boolean} />;
  }

  if (isEmpty) {
    return (
      <>
        <Helmet>
          <title>Cart: {merchstore.name}</title>
        </Helmet>
        <div className="flex flex-col mt-8 lg:mt-0 lg:px-0 lg:py-8 items-center lg:h-2/3">
          <div className="w-11/12 lg:w-10/12">
            <Card className="card-wrapper w-full">
              <div className="flex flex-col w-full pb-16">
                <h3 className="w-full font-bold text-center text-lg text-merch-dark-gray text-3xl md:text-4xl my-8">
                  Your Cart is Empty
                </h3>
                <div className="self-center">
                  <CartKeepShoppingCTA
                    display={true}
                    type="primary"
                    classes="w-64"
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </>
    );
  }

  const hasMemberEmail = Boolean(member && member.email);
  const hasGiftCardsEnabledButNoMemberEmail =
    giftCardsEnabled && !hasMemberEmail;

  return (
    <>
      <Helmet>
        <title>Cart: {merchstore.name}</title>
      </Helmet>
      <div className="relative flex justify-center">
        <div className="flex flex-col lg:px-0 lg:py-8 items-center lg:w-10/12">
          <h1 className="cart-header font-bold text-center text-lg text-merch-dark-gray my-3 h-full lg:mb-8 lg:text-4xl">
            Your Cart
          </h1>
          <Card className="card-wrapper ">
            <div className="flex flex-col lg:flex-row">
              <div className="pt-8 px-4 lg:pl-16 lg:p-8 w-full lg:w-4/6">
                <CartProductList />
              </div>
              <div
                className="
                p-8 pb-10 px-4 bg-gray-50
                flex flex-col items-start 
                border-t-2 border-gray-100 rounded-b-3xl
                lg:w-2/6 lg:p-8 lg:border-l-2 lg:border-gray-100 lg:rounded-none lg:rounded-tr-24 lg:rounded-br-24"
              >
                <h2 className="font-bold text-2xl">Order Total</h2>
                <CartSummary storeCode={merchstore?.storeCode || ''} />
                <div className="cart-checkout-details w-full mb-2">
                  <CartCheckoutCTA
                    disabled={isMerchStoreClosed}
                    hasGiftCardsEnabledButNoMemberEmail={
                      hasGiftCardsEnabledButNoMemberEmail
                    }
                  />
                  <CartAdminPayerLabel display={isAdminPayer} />
                  <CartKeepShoppingCTA
                    display={isIndividualPayer}
                    classes="w-full my-5"
                  />
                  {isAutoProcessingEnabled && <OrderProcessingDate />}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
