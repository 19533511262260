import './styles.scss';

export const Footer = (merchstore: any): JSX.Element => {
	merchstore = merchstore.merchstore;
  const year = new Date().getFullYear();
  const footerMessageEmpty = !merchstore.footerMessage;
  const footLinksEmpty = merchstore.footerLinks && merchstore?.footerLinks?.every((link: any) => link.name === '' && link.url === '');

  return (
    <div className="store-footer-container flex flex-col px-0 mt-24 bottom-0 position-fixed">
      {!footerMessageEmpty || !footLinksEmpty ? 
			  <div className="store-footer flex items-center xl:justify-start justify-center mr-auto flex-row lg:px-200 px-50 py-20 flex-wrap w-full">
			  	<div className="flex max-w-screen-sm mx-5 lg:text-left text-center">{merchstore.footerMessage}</div>
			  	<div className="flex flex-col  py-10">
            {merchstore.footerLinks && merchstore.footerLinks.map((link: any, index: number) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noreferrer"
                className="store-footer-link flex flex-row w-full">{link.name || link.url}</a>
            )).slice(0, 3)}
			  	</div>
			  </div> 
      : null }
      <div className="flex items-center justify-center flex-row flex-wrap space-x-4 p-10">
        <a
          href="https://www.merchology.com/pages/contact-us"
          target="_blank"
          rel="noreferrer"
          className="merch-footer-link text-merch-dark-gray"
        >
          Contact Us
        </a>
        <a
          href="https://www.merchology.com/pages/products-sizing-tabs"
          target="_blank"
          rel="noreferrer"
          className="merch-footer-link text-merch-dark-gray"
        >
          Sizing
        </a>
        <a
          href="https://www.merchology.com/pages/merch-lookbooks"
          target="_blank"
          rel="noreferrer"
          className="merch-footer-link text-merch-dark-gray"
        >
          Lookbooks
        </a>
        <a
          href="https://www.merchology.com/pages/our-process"
          target="_blank"
          rel="noreferrer"
          className="merch-footer-link text-merch-dark-gray"
        >
          Our Process
        </a>
        <a
          href="https://www.merchology.com/"
          target="_blank"
          rel="noreferrer"
          className="merch-footer-text mt-4 md:mt-0 text-center"
        >
          Powered by <b>Merchology.com</b> ©{year} - All rights reserved.
        </a>
      </div>
    </div>
  );
};
