import { IMerchStore } from '@merchstores/frontend/reduxTypes';
import { resolveMerchstoreSubdomain } from './Subdomain';

export function resolveMerchstoreUrl(merchStore: IMerchStore): string {
  if (merchStore.customDomain) {
    if (merchStore.customDomain.split('.').length < 3) {
      return `https://www.${merchStore.customDomain}`;
    } else {
      return `https://${merchStore.customDomain}`;
    }
  }

  const subdomain = resolveMerchstoreSubdomain();
  return `https://${subdomain}.merchologysolutions.com`;
}
