export function chargeShippingUnlessExplicitFalse(
  settingValue?: string | boolean | null
): boolean {
  const isExplicitFalse = String(settingValue) === 'false';

  if (isExplicitFalse) {
    return false;
  }

  return true;
}
