import { ICartItem, ICartProduct } from '@merchstores/frontend/reduxTypes';
import { mapProductsByHandle } from './CartProducts';

export interface ICartProductStyle {
  styleKey: string;
  product: ICartProduct;
  items: Array<ICartItem>;
}

export function buildProductStyles(
  items: Array<ICartItem>,
  products: Array<ICartProduct>
): Array<ICartProductStyle> {
  const productsByHandle = mapProductsByHandle(products);

  const cartProductStyles = new Map();

  items.forEach((item) => {
    if (!item.handle) {
      throw new Error(
        'Missing product handle for item ' + JSON.stringify(item)
      );
    }

    const styleKey = `${item.handle}-${item.artwork}-${item.decorationType}-${item.decorationLocation}`;

    if (!cartProductStyles.has(styleKey)) {
      cartProductStyles.set(styleKey, {
        styleKey: styleKey,
        product: productsByHandle.get(item.handle),
        items: [],
      });
    }

    cartProductStyles.get(styleKey).items.push(item);
  });

  const styleList: Array<ICartProductStyle> = Array.from(
    cartProductStyles.values()
  );
  return styleList;
}
