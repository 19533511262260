import { Cloudinary } from 'cloudinary-core';
// this function must be refactored to force intergers or strings in width and height,
// I saw both uses in the wild

export const cloudinaryApplyWidthHeight = (
  url: string,
  width: any,
  height: any
) => {
  if (url) {
    const uploadIndex = url.indexOf('/upload/');
    const cfitRegex = /(c_fit,)\w+/;
    const heightRegex = /(h_[0-9],)\w+/;
    const widthRegex = /(w_[0-9]\/)\w+/;
    url = url.replace(cfitRegex, '');
    url = url.replace(heightRegex, '');
    url = url.replace(widthRegex, '');

    if (uploadIndex > -1) {
      const newUrl =
        url.slice(0, uploadIndex + 8) +
        `c_fit${height ? ',h_' + height : ''}${width ? ',w_' + width : ''}/` +
        url.slice(uploadIndex + 8, url.length);

      return newUrl;
    } else {
      return url;
    }
  }
  return url;
};

export const getPublicIdFromUrl = (url: URL | string) => {
  /**
   * Match the 'GOT' Or 'MERCHSTORES' as the first part
   * Match the image file name without the extension as the second part.
   * ie 'Amazon.png' will extract 'Amazon', and 'Amazon.logo.png' will extract 'Amazon.logo'
   */
  const urlPartsMatch = /\/(GOT|MERCHSTORES)\/(.+?)(\.[^.]*$|$)/g.exec(
    url.toString()
  );
  if (urlPartsMatch) {
    const app = urlPartsMatch[1];
    const publicId = urlPartsMatch[2];
    return `${app}:${publicId}`;
  }
};

export const getPublicIdFromUploadedImage = (url: string): string => {
  const matches = /upload\/(.*)/gi.exec(url);
  if (matches !== null && matches.length > 0) {
    return matches[1];
  }
  return '';
};

export const getCloudinary = (
  cloudinarySource?: 'merchstores' | 'group-order-tool'
) =>
  new Cloudinary({
    cloud_name: 'merchology',
    upload_preset: cloudinarySource ? cloudinarySource : 'merchstores',
  });
