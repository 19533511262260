import IThumbnailProps from '.';
import './styles.scss';

export const Thumbnail = ({
  name,
  imageUrl,
  onClick,
  selected,
}: IThumbnailProps) => {
  return (
    <div className={`thumbnail ${selected ? 'active' : ''}`}>
      <img alt={name} src={imageUrl?.toString()} onClick={onClick} />
    </div>
  );
};
