import { combineReducers } from 'redux';
// Import custom components
import merchstoreReducer from './merchstore';
import cartReducer from './cart';
import userReducer from './user';

const rootReducer = combineReducers({
  cart: cartReducer,
  merchstore: merchstoreReducer,
  user: userReducer,
});

export default rootReducer;
