import { DateTime } from 'luxon';

export function getLocalDateText(date: string) {
  let dt;

  if ((date + '').indexOf('/') > -1) {
    dt = DateTime.fromFormat(date, 'MM/dd/yyyy', { zone: 'utc' });
  } else {
    dt = DateTime.fromISO(date, { zone: 'utc' });
  }

  return dt.isValid ? dt.setZone('utc').toLocaleString() : '';
}

export function getLocalDateFullMonthText(date: string) {
  const dt = DateTime.fromISO(date, { zone: 'utc' });
  return dt.isValid ? dt.setZone('utc').toFormat('DDD') : '';
}
