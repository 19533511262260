import './styles.scss';
import { IFormInputProps } from './index';
import { MdErrorOutline, MdHelpOutline } from 'react-icons/md';
import { useState } from 'react';
import { useEffect } from 'react';
import { Tooltip } from '../Tooltip';

export const FormInput = (props: IFormInputProps) => {
  // Define the actual props needed in the index file
  const [emptyInput, setEmptyInput] = useState(false);
  let onChangeProps = {};
  if (props.onChange) {
    onChangeProps = {
      ...onChangeProps,
      onChange: props.onChange,
    };
  }
  useEffect(() => {
    if (props.defaultValue) {
      if (props.defaultValue !== '') {
        setEmptyInput(false);
      } else {
        setEmptyInput(true);
      }
    } else {
      setEmptyInput(true);
    }
  }, [props.defaultValue]);

  return (
    <>
      <div
        className={`form-input-container flex flex-col w-full form-input ${props.inputStyle === 'two' ? 'mb-5' : ''} `}
      >
        {props.inputStyle === 'two' ? (
          <div className="flex justify-between mb-2">
            <div className="flex items-center">
              {props.label && (
                <label
                  htmlFor={props.name}
                  className="text-sm text-merch-dark-gray font-bold"
                >
                  {props.label}
                </label>
              )}
              {props.tooltip && (
                <Tooltip content={props.tooltip}>
                  <div className="opacity-50 ml-2">
                    <MdHelpOutline />
                  </div>
                </Tooltip>
              )}
            </div>
            {props.helpText && (
              <div
                onClick={props.onClick ? props.onClick : ''}
                className={`text-xs mr-3 ${props.onClick ? 'cursor-pointer font-bold text-merch-orange' : 'opacity-50 text-merch-dark-gray italic'}`}
              >
                {props.helpText}
              </div>
            )}
          </div>
        ) : (
          <>
            {props.label && (
              <label
                htmlFor={props.name}
                className={`textbox-label ${emptyInput ? 'empty' : ''}`}
              >
                {props.label}
              </label>
            )}
          </>
        )}
        {props.textArea ? (
          <textarea
            type={props.type}
            name={props.name}
            rows={props.textAreaLines ? props.textAreaLines : 6}
            disabled={props.disabled}
            placeholder={props.placeholder ? props.placeholder : ''}
            {...props.register(props.name, { required: props.required })}
            defaultValue={props.defaultValue ? props.defaultValue : ''}
            className={`
                    ${props.disabled ? 'opacity-50' : ''}
                    ${props.errors[props.name] && Object.keys(props.errors[props.name]).length > 0 ? 'error' : ''}
                    ${props.inputStyle === 'two' ? `form-input-textarea input-type-two bg-white text-sm font-medium group-order-form-select p-3 border border-gray-300 rounded-md relative ${props.textAreaLines ? '' : 'h-110'}` : 'form-input-textarea'}
                    ${props.textArea ? 'resize-none' : ''}
					          ${props.label ? '' : 'no-label'} 
                    ${props.textAreaClassName ? props.textAreaClassName : ''}
                  `}
            {...onChangeProps}
          />
        ) : (
          <input
            id={props.id}
            type={props.type}
            name={props.name}
            min={props.min && props.min}
            max={props.max && props.max}
            disabled={props.disabled}
            placeholder={props.placeholder ? props.placeholder : ''}
            {...props.register(props.name, { required: props.required })}
            defaultValue={props.defaultValue ? props.defaultValue : ''}
            className={`
                    form-input w-full
                    ${props.disabled ? 'opacity-50' : ''}
                    ${props.errors[props.name] && Object.keys(props.errors[props.name]).length > 0 ? 'error' : ''}
                    ${props.inputStyle === 'two' ? 'input-type-two bg-white text-sm font-medium group-order-form-select p-3 border border-gray-300 rounded-md relative' : 'form-input'}
					          ${props.label ? '' : 'no-label'}
                    ${props.className ? props.className : ''}
                  `}
            onFocus={() => setEmptyInput(false)}
            onBlur={(ev: any) => {
              if (ev.target.value === '') {
                setEmptyInput(true);
              } else {
                setEmptyInput(false);
              }
            }}
            {...onChangeProps}
          />
        )}
        {props.subText && (
          <div className="text-xs font-merch-dark-gray opacity-50">
            {props.subText}
          </div>
        )}
        {props.linkSubText && (
          <div className="text-xs font-merch-dark-gray">
            <a
              className="text-merch-orange"
              href={`//${props.linkSubText}`}
              target="_blank"
              rel="noreferrer"
            >
              {props.linkSubText}
            </a>
          </div>
        )}
        {props.htmlSubText && (
          <div className="text-xs font-merch-dark-gray opacity-50">
            {props.htmlSubText}
          </div>
        )}
        {props.errors[props.name] &&
          Object.keys(props.errors[props.name]).length > 0 && (
            <div className="flex-row">
              <div className="inline-block mt-1">
                <MdErrorOutline />
              </div>
              {props.errors[props.name]?.type === 'required' ? (
                <span className="error">{props.label} is required</span>
              ) : props.errors[props.name]?.types?.custom ? (
                <span className="error">
                  {props.errors[props.name]?.types.custom}
                </span>
              ) : (
                <span className="error">
                  {props.errors[props.name]?.message}
                </span>
              )}
            </div>
          )}
      </div>
    </>
  );
};
