import { useState, useEffect } from 'react';
import { CTA } from '@merchstores/shared/elements/Cta';
import { useStore } from 'react-redux';
import { toast } from 'react-toastify';

import {
  buildCheckoutFields,
  requestCheckoutUrl,
  ICheckoutFields,
} from './CartCheckout';
import Bluebird from 'bluebird';
import { CartContext } from '@merchstores/frontend/context/Cart';
import { useContext } from 'react';

export interface ICartCheckoutCTAProps {
  display?: boolean;
  disabled?: boolean;
  hasGiftCardsEnabledButNoMemberEmail?: boolean;
}

export const CartCheckoutCTA: React.FC<ICartCheckoutCTAProps> = (
  props: ICartCheckoutCTAProps
) => {
  const reduxStore = useStore();
  const [waitForSubmit, setWaitForSubmit] = useState(false);
  const {
    state: { member },
  } = useContext(CartContext);

  let fallbackCheckoutUrl: string | null = null;
  let checkoutSuccess = false;

  const resolveCheckoutUrl = async (): Promise<string | null> => {
    // use the updated current store state
    const cart = reduxStore.getState().cart;
    const merchstore = reduxStore.getState().merchstore;
    const checkoutFields: ICheckoutFields = buildCheckoutFields(
      { ...cart, member },
      merchstore
    );

    const checkoutResponse = await requestCheckoutUrl(checkoutFields).catch(
      (e) => {
        console.error('error getting checkout URL', e);
        return null;
      }
    );

    if (checkoutResponse?.checkoutUrl) {
      return checkoutResponse.checkoutUrl;
    }

    return null;
  };

  const resolveAndRedirectToCheckout = async () => {
    if (checkoutSuccess) {
      return false;
    }

    let checkoutUrl = await resolveCheckoutUrl();

    if (!checkoutUrl) {
      console.log('Using checkout fallback URL');
      console.log(fallbackCheckoutUrl);
      checkoutUrl = fallbackCheckoutUrl;
    }

    if (!checkoutUrl) {
      return false;
    }

    setWaitForSubmit(false);
    checkoutSuccess = true;

    window.location.assign(checkoutUrl);
  };

  const handleProceedToCheckout = async () => {
    checkoutSuccess = false;
    const user = reduxStore.getState().user;

    if (!user.loggedIn && user.passwordRequired) {
      return toast.error(
        `Sorry, can't proceed to checkout. You need to login first`
      );
    }
    if (props.hasGiftCardsEnabledButNoMemberEmail) {
      return toast.error(`Please enter your email.`);
    }
    setWaitForSubmit(true);

    // Show error to user after 65 seconds and re-enable the checkout button
    Bluebird.delay(65 * 1000).then(() => {
      if (!fallbackCheckoutUrl) {
        toast.info(`Sorry, there was a connection error. Please try again`);
      }
      setWaitForSubmit(false);
    });

    resolveAndRedirectToCheckout();

    // Try a second time after 15 seconds
    await Bluebird.delay(15 * 1000);
    resolveAndRedirectToCheckout();

    // Try a third time after 20 seconds
    await Bluebird.delay(20 * 1000);
    resolveAndRedirectToCheckout();

    // Try a fourth time after 25 seconds
    await Bluebird.delay(25 * 1000);
    toast.info(`Connecting...`);

    resolveAndRedirectToCheckout();
  };

  useEffect(() => {
    /*
      This call preloads the checkoutUrl to prevent a long retry window 
      if the user clicks the proceed to checkout button.
    */
    resolveCheckoutUrl().then((url: string | null) => {
      if (url) {
        fallbackCheckoutUrl = url;
      }
    });
  }, []);

  if (props.display === false) {
    return null;
  }

  return (
    <div className="cart-checkout-cta">
      <CTA
        type="merchstore-primary"
        size="standard"
        classes="text-sm w-full"
        onClick={handleProceedToCheckout}
        disabled={props.disabled || waitForSubmit}
      >
        {waitForSubmit ? 'Loading...' : 'Proceed to Checkout'}
      </CTA>
    </div>
  );
};
