import { useState } from 'react';
import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { IProductPageHeaderProps } from '.';
import './styles.scss';

import { MdMailOutline } from 'react-icons/md';
import { IMerchStore, IStoreState } from '@merchstores/frontend/reduxTypes';

export const ProductPageHeader = (props: IProductPageHeaderProps) => {
  // Define the actual props needed in the index file

  const merchstore: IMerchStore = useSelector(
    (state: IStoreState) => state.merchstore,
    shallowEqual
  );
  const [product, setProduct] = useState({ id: '', title: '', variants: [] });

  useEffect(() => {
    if (props.product) {
      setProduct(props.product);
    }
  }, [props.product]);

  return (
    <div className="product-page-header text-12 text-merch-text-disabled relative flex items-center flex-col w-full flex-0 pt-29 pl-5 pr-5">
      {merchstore && product && (
        <div className="flex justify-between w-full items-center max-w-7xl">
          <div className="breadcrumb flex items-center">
            <div className="breadcrumb-text breadcrumb-before">
              &nbsp;&lt;&nbsp;
            </div>
            <a href="/" className="breadcrumb-link store-link text-12">
              {merchstore.name}
            </a>
            <div className="breadcrumb-text breadcrumb-after flex">
              &nbsp;&gt;&nbsp;<p className="text-12">{product.title}</p>
            </div>
          </div>
          <a
            href="mailto:sales@merchology.com"
            className="contact-us-link flex items-center text-12"
          >
            Contact us about this product
            <div className="ml-1">
              <MdMailOutline size="20px" />
            </div>
          </a>
        </div>
      )}
    </div>
  );
};
