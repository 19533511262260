import { ILogoProps } from '.';
import './styles.scss';

export const Logo = (props: ILogoProps) => {
  return (
    <div className={`logo ${props.size} bg-white`}>
      <img loading="lazy" src={props.imgUrl} alt="Logo" />
      {props.children}
    </div>
  );
};
