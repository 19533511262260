import { BigNumber } from 'bignumber.js';
import { ICartItem, ICartProduct } from '@merchstores/frontend/reduxTypes';
import { mapProductsByHandle, mapProductVariantsById } from './CartProducts';

interface MoneyV2 {
  amount: string;
  currency: string;
}

function shopifyMoneyAmount(moneyValue?: MoneyV2): BigNumber {
  if (!moneyValue) {
    return new BigNumber(0);
  }

  const moneyAmount = moneyValue.amount ? moneyValue.amount : 0;
  return new BigNumber(moneyAmount);
}

export function calculateSubtotalPrice(
  items: Array<ICartItem>,
  products: Array<ICartProduct>
): BigNumber {
  let subtotalPrice = new BigNumber(0);

  const productsByHandle = mapProductsByHandle(products);

  items.forEach((item) => {
    const product = productsByHandle.get(item.handle as string);

    if (!product) return;

    const productVariants = mapProductVariantsById(product);
    const productVariant = productVariants.get(item.id);

    if (!productVariant) return;

    const unitPrice = shopifyMoneyAmount(productVariant.price);
    const quantity = item.quantity;
    subtotalPrice = subtotalPrice.plus(unitPrice.times(quantity));
  });

  return subtotalPrice;
}

export function calculateSubtotalWithoutSavings(
  items: Array<ICartItem>,
  products: Array<ICartProduct>
): BigNumber {
  const productsByHandle = mapProductsByHandle(products);

  let subtotalPrice = new BigNumber(0);

  items.forEach((item) => {
    const product = productsByHandle.get(item.handle as string);

    if (!product) return;

    const productVariants = mapProductVariantsById(product);
    const productVariant = productVariants.get(item.id);

    if (!productVariant) return;

    let unitPrice = shopifyMoneyAmount(productVariant.price);
    const quantity = item.quantity;

    const variantCompareAtPrice = shopifyMoneyAmount(
      productVariant.compareAtPrice
    );
    const variantPrice = shopifyMoneyAmount(productVariant.price);

    if (variantCompareAtPrice > variantPrice) {
      unitPrice = variantCompareAtPrice;
    }

    subtotalPrice = subtotalPrice.plus(unitPrice.times(quantity));
  });

  return subtotalPrice;
}

export function calculateSavings(
  subtotalWithSavings: BigNumber,
  subtotalWithoutSavings: BigNumber
): BigNumber {
  return subtotalWithoutSavings.minus(subtotalWithSavings);
}
