import axios from 'axios';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import { Header } from './components/Header';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { fetchStore } from './actions/merchstore';
import { clearCart } from './actions/cart';
import { Page404 } from './components/Page404';
import { ProductPageLayout } from '@merchstores/frontend/layouts/ProductPageLayout';
import { CartPage } from '@merchstores/frontend/layouts/CartPage';
import { Footer } from './components/Footer';
import { CollectionPageLayout } from './layouts/CollectionPageLayout/component';
import { MerchStoreLogin } from './components/MerchStoreLogin';
import { resolveMerchstoreSubdomain } from './components/Merchstore';
import { loginToMerchStore } from './actions/user';
import { isAuthenticated } from './auth';
import { CartContext } from '@merchstores/frontend/context/Cart';
import { resolveMerchStoreApiUrl } from './api/merchstore';

export const App = () => {
  const dispatch = useDispatch();

  const merchstore = useSelector((state) => state.merchstore, shallowEqual);
  const user = useSelector((state) => state.user, shallowEqual);

  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);

  const { setCartMember } = useContext(CartContext);
  // Check if there was an email param passed in via URL
  const emailParam = new URL(window.location.toLocaleString()).searchParams.get(
    'member_email'
  );

  useEffect(() => {
    if (emailParam) {
      setCartMember({ email: emailParam });
    }
  }, []);

  useEffect(() => {
    const storeSubdomain = resolveMerchstoreSubdomain();
    dispatch(fetchStore(storeSubdomain));
  }, []);

  useEffect(() => {
    if (merchstore) {
      // Check if there is a password
      axios
        .post(
          resolveMerchStoreApiUrl(
            '/.netlify/functions/merchstore-api/checkMerchStorePassword'
          ),
          {
            subdomain: merchstore.subdomain,
          }
        )
        .then((res) => {
          if (res.data.passwordRequired) {
            setPasswordRequired(true);
            let storeCodes = user.storeCode;

            if (storeCodes.indexOf(merchstore.storeCode) < 0) {
              storeCodes = [...user.storeCode, merchstore.storeCode];
            }
            const isUserAuthenticated = isAuthenticated(merchstore.storeCode);
            if (!isUserAuthenticated) {
              dispatch(
                loginToMerchStore({
                  loggedIn: false,
                  storeCode: [...storeCodes],
                  passwordRequired: true,
                })
              );
            }
          } else {
            const storeCodeIndex = user.storeCode.indexOf(merchstore.storeCode);
            user.storeCode.splice(storeCodeIndex, 1);
            dispatch(
              loginToMerchStore({
                loggedIn: true,
                storeCode: [...user.storeCode],
                passwordRequired: false,
              })
            );
          }
        })
        .catch((err) => {
          console.error('Failed getting MerchStore Password', err);
        });
    }
  }, [merchstore]);

  useEffect(() => {
    if (passwordRequired) {
      let storeMatch = false;
      // Loop through the user's stores that they have access to and grant them access
      for (const userStore of user.storeCode) {
        if (userStore === merchstore.storeCode) {
          storeMatch = true;
        }
      }
      if (!user.loggedIn || !storeMatch) {
        setLoginModalOpen(true);
      }
    }
  }, [passwordRequired]);

  const onCloseModal = () => {
    setLoginModalOpen(false);
    document.body.style.overflow = 'scroll';
  };

  if (loginModalOpen) {
    document.body.style.overflow = 'hidden';
  }

  // When user completes checkout, set localstorage so that the cart clears on next visit
  useEffect(() => {
    const isCheckoutComplete =
      localStorage.getItem('checkoutComplete') === 'true';
    if (isCheckoutComplete) {
      dispatch(clearCart());
      localStorage.setItem('checkoutComplete', false);
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content="" />
        <title>Merchology MerchStore</title>
      </Helmet>
      {loginModalOpen && <MerchStoreLogin onCloseModal={onCloseModal} />}
      <BrowserRouter basename={'/'}>
        <Header />
        <ScrollContext>
          <Switch>
            <Route exact path={'/'} component={CollectionPageLayout} />
            <Route path={'/404'} component={Page404} />
            <Route exact path={'/cart'} component={CartPage} />
            <Route exact path={'/cart/clear'} component={CartPage} />
            <Route path={'/:productHandle'} component={ProductPageLayout} />
          </Switch>
        </ScrollContext>
      </BrowserRouter>
      <ToastContainer position="bottom-right" />
      <Footer merchstore={merchstore} />
    </>
  );
};
