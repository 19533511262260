import { ICartItem } from '@merchstores/frontend/reduxTypes';
import { ICartProductStyle } from './CartProductStyles';

export function countTotalItems(items: Array<ICartItem>): number {
  let counter = 0;

  items.forEach((item) => {
    counter += item.quantity;
  });

  return counter;
}

export function countStyles(styles: Array<ICartProductStyle>): number {
  return styles.length;
}

export function countProducts(items: Array<ICartItem>): number {
  const productHandles = new Set(items.map((item) => item.handle));
  return productHandles.size;
}
