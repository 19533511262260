import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_ITEM,
  CLEAR_CART,
  REFRESH_CART_PRODUCTS,
} from '../constants/ActionTypes';

import {
  CartAction,
  ICartItem,
  ICartProduct,
} from '@merchstores/frontend/reduxTypes';
import { countTotalItems } from '@merchstores/frontend/components/Cart/CartCount';
import {
  itemListFilteredByProducts,
  itemListMergeItem,
} from '@merchstores/frontend/components/Cart/CartItems';
import {
  itemListReplaceItem,
  itemListRemoveItem,
} from '@merchstores/frontend/components/Cart/CartItems';

export default function cartReducer(
  cartState = {
    itemCount: 0,
    items: [],
    products: [],
  },
  action: CartAction
) {
  const targetItem = action.item;

  let items = [];
  let itemCount = 0;

  switch (action.type) {
    case REFRESH_CART_PRODUCTS:
      items = itemListFilteredByProducts(
        cartState.items,
        action.products as Array<ICartProduct>
      );
      itemCount = countTotalItems(items);
      return {
        ...cartState,
        items,
        itemCount,
        products: action.products as Array<ICartProduct>,
      };

    case ADD_TO_CART:
      items = itemListMergeItem(cartState.items, targetItem as ICartItem);
      itemCount = countTotalItems(items);
      return { ...cartState, items, itemCount };

    case UPDATE_CART_ITEM:
      items = itemListReplaceItem(cartState.items, targetItem as ICartItem);
      itemCount = countTotalItems(items);
      return { ...cartState, items, itemCount };

    case REMOVE_FROM_CART:
      items = itemListRemoveItem(cartState.items, targetItem as ICartItem);
      itemCount = countTotalItems(items);

      return { ...cartState, itemCount, items };

    case CLEAR_CART:
      return { ...cartState, itemCount: 0, items: [], products: [] };

    default:
      return cartState;
  }
}
