import { getLocalDateFullMonthText } from '@merchstores/frontend/components/DateTime';
import { IMerchStore, IStoreState } from '@merchstores/frontend/reduxTypes';
import { shallowEqual, useSelector } from 'react-redux';

export interface IProcessingDateProps {
  display?: boolean;
}

export const OrderProcessingDate: React.FC<IProcessingDateProps> = (
  props: IProcessingDateProps
) => {
  const merchstore: IMerchStore = useSelector(
    (state: IStoreState) => state.merchstore,
    shallowEqual
  );
  const display = props.display || true;
  const nextProcessingDate = merchstore.nextProcessingDate;

  if (!display || !nextProcessingDate) {
    return null;
  }

  return (
    <div className="flex flex-col items-center mt-5">
      <p>Your order will be processed on:</p>
      <p className="text-lg font-bold">
        {getLocalDateFullMonthText(nextProcessingDate)}
      </p>
    </div>
  );
};
