const HEADER_COLOR_STYLE_ELEMENT_ID = 'style-store-header-color';

function isValidCssColor(cssColor: string): boolean {
  return CSS.supports('color', cssColor);
}

function getTextColor(backgroundColor: string): string {
  let normalizedHex = backgroundColor.replace(/^#/, '');
  if (normalizedHex.length === 3) {
    normalizedHex =
      normalizedHex[0] +
      normalizedHex[0] +
      normalizedHex[1] +
      normalizedHex[1] +
      normalizedHex[2] +
      normalizedHex[2];
  }
  const red = parseInt(normalizedHex.substring(0, 2), 16);
  const green = parseInt(normalizedHex.substring(2, 4), 16);
  const blue = parseInt(normalizedHex.substring(4, 6), 16);

  if (red * 0.299 + green * 0.587 + blue * 0.114 > 186) {
    return '#4B4B55';
  }
  return '#EBEBF0';
}

export function applyHeaderColor(headerColor: string): boolean {
  if (document.getElementById(HEADER_COLOR_STYLE_ELEMENT_ID)) {
    // Color already loaded
    return true;
  }

  if (!isValidCssColor(headerColor)) {
    return false;
  }

  const textColor = getTextColor(headerColor);

  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  head.appendChild(style);

  const css = `
    header.storefront { 
      background-color: ${headerColor} !important; 
    }
    .nav-text {
      color: ${textColor} !important;
    }
    .nav-text-underline {
      background-color: ${textColor} !important;
    }
    .filter-nav {
      background-color: ${headerColor} !important;
    }
    .store-footer {
      background-color: ${headerColor} !important;
      color: ${textColor} !important;
    }
    .store-footer-link {
      color: ${textColor} !important;
    }
    .search-box {
      border-color: ${textColor} !important;
      color: ${textColor} !important;
    }
    .search-text {
      color: ${textColor} !important;
    }
    .search-text::placeholder {
      color: ${textColor} !important;
    }
  `;
  style.id = HEADER_COLOR_STYLE_ELEMENT_ID;
  style.appendChild(document.createTextNode(css));

  return true;
}
