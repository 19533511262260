import { Helmet } from 'react-helmet';
import { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
//import { useHistory } from "react-router";
//import { getCollectionByHandle } from "../../queries/storefront-graphql";
import { Loading } from '@merchstores/shared/components/Loading';
//import { getProductsByStoreTag } from "../../queries/storefront-graphql";
import { CollectionPageHeader } from '@merchstores/frontend/components/CollectionPage/CollectionPageHeader/component';
import { CollectionPageSortFilter } from '@merchstores/frontend/components/CollectionPage/CollectionPageSortFilter';
import { CollectionPageProductTable } from '@merchstores/frontend/components/CollectionPage/CollectionPageProductTable';
import { IMerchStore, IStoreState } from '@merchstores/frontend/reduxTypes';
import { fetchFilteredCollectionProducts } from '@merchstores/frontend/api/products/MerchStoreProducts';
import { defaultPriceFilter } from '@merchstores/frontend/queries/storefront-products-2022-10';
import './styles.scss';
import { MdSearch } from 'react-icons/md';

const DEFAULT_PRICE_RANGE = { low: 0, high: 5000 };

export const CollectionPageLayout: React.FC = () => {
  //const history = useHistory();
  const merchstore: IMerchStore = useSelector(
    (state: IStoreState) => state.merchstore,
    shallowEqual
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sortChosen, setSortChosen] = useState('sort_default');
  const [currentPage, setCurrentPage] = useState(0);

  const [productFilters, setProductFilters] = useState([]);
  const [brandFilters, setBrandFilters] = useState([]);

  const [productFilterChosen, setProductFilterChosen] = useState('All');
  const [priceFilterChosen, setPriceFilterChosen] =
    useState(DEFAULT_PRICE_RANGE);
  const [brandFilterChosen, setBrandFilterChosen] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');

  async function fetchData(merchstore: IMerchStore) {
    const storeCode = merchstore.storeCode;

    if (storeCode) {
      const products = await fetchFilteredCollectionProducts({
        storeCode: storeCode,
        productFilter: '',
        brandFilter: '',
        priceFilter: defaultPriceFilter,
        sortChosen: '',
        cursor: undefined,
      });
      const searchFilteredProducts = searchFilterProducts(products);
      const sortedProducts = sortProducts(searchFilteredProducts);
      const tagFilteredProducts = tagFilterProducts(sortedProducts);
      setAllProducts(tagFilteredProducts);
      setIsLoading(false);
      setTimeout(() => setIsTableLoading(false), 500);
    }
  }

  const sortProducts = (products: any[]) => {
    if (merchstore.productsOrder?.length && sortChosen === 'sort_default') {
      const desiredOrder = merchstore.productsOrder;
      const productsToSort = products.filter((product) =>
        desiredOrder.includes(product.id)
      );
      const productsNoSort = products.filter(
        (product) => !desiredOrder.includes(product.id)
      );
      productsToSort.sort(
        (a, b) => desiredOrder.indexOf(a.id) - desiredOrder.indexOf(b.id)
      );
      return [...productsToSort, ...productsNoSort];
    }
    return products;
  };

  const searchFilterProducts = (products: any[]) => {
    if (searchTerm) {
      const titleMatches = products.filter((product: any) =>
        product.title?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const vendorMatches = products.filter((product: any) =>
        product.vendor?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const productTypeMatches = products.filter((product: any) =>
        product.productType?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      products = Array.from(
        new Set([...titleMatches, ...vendorMatches, ...productTypeMatches])
      );
    }
    return products;
  };

  function tagFilterProducts(products: any[]) {
    let newProductsArr = products;
    newProductsArr = newProductsArr.filter(
      (product) => !product.tags.includes('discontinued')
    );
    newProductsArr = newProductsArr.filter(
      (product) => !product.tags.includes('DISCONTINUED')
    );
    return newProductsArr;
  }

  useEffect(() => {
    fetchData(merchstore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchstore, sortChosen]);

  useEffect(() => {
    setIsTableLoading(true);
    async function fetchFilteredData() {
      const storeCode = merchstore.storeCode || '';
      const hasFilter =
        productFilterChosen !== 'All' ||
        brandFilterChosen !== 'All' ||
        priceFilterChosen;
      if (!storeCode || !hasFilter) {
        setFilteredProducts([]);
        return false;
      }
      const filteredProducts = await fetchFilteredCollectionProducts({
        storeCode,
        productFilter: productFilterChosen,
        brandFilter: brandFilterChosen,
        priceFilter: priceFilterChosen,
        sortChosen: sortChosen,
      });
      const searchFilteredProducts = searchFilterProducts(filteredProducts);
      const sortedProducts = sortProducts(searchFilteredProducts);
      const tagFilteredProducts = tagFilterProducts(sortedProducts);
      setFilteredProducts(tagFilteredProducts);
      setIsLoading(false);
      setTimeout(() => setIsTableLoading(false), 500);
    }
    fetchFilteredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    merchstore,
    sortChosen,
    productFilterChosen,
    brandFilterChosen,
    priceFilterChosen,
    searchTerm,
  ]);

  useEffect(() => {
    // Sets the available product & brand filters based on the store products
    if (allProducts) {
      const productTypeArray: string[] = ['All'];
      const brandTypeArray: string[] = ['All'];

      for (const product of allProducts) {
        if (!productTypeArray.includes(product.productType)) {
          productTypeArray.push(product.productType);
        }

        if (!brandTypeArray.includes(product.vendor)) {
          brandTypeArray.push(product.vendor);
        }
      }
      setProductFilters(productTypeArray.sort());
      setBrandFilters(brandTypeArray.sort());
    }
  }, [allProducts]);

  useEffect(() => {
    setSortChosen('sort_default');
    setProductFilterChosen('All');
    setBrandFilterChosen('All');
    setPriceFilterChosen(DEFAULT_PRICE_RANGE);
  }, [searchTerm]);

  if (isLoading) return <Loading isLoading={isLoading as boolean} />;

  return (
    <>
      <div className="filter-nav flex w-full justify-center items-end bg-white">
        <div className="filter-nav-links flex w-full justify-center items-bottom items-end flex-wrap">
          {productFilters.slice(0, 10).map((productType: string) => (
            <div
              key={productType}
              className="nav-text mx-2 px-2 font-bold text-lg cursor-pointer"
              onClick={() => setProductFilterChosen(productType)}
            >
              {productType}
              <div
                className={`nav-text-underline w-full h-1 ${productFilterChosen === productType ? 'block' : 'hidden'}`}
              />
            </div>
          ))}
          <div className="search-box flex flex-row border-b-2 w-150 mx-4">
            <input
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-text mx-2 px-2 font-bold text-lg w-full cursor-text bg-transparent outline-none"
              placeholder="Search"
            />
            <MdSearch size="27px" />
          </div>
        </div>
      </div>

      <Helmet>
        <meta name="description" content={merchstore.storeMessage} />
        <title>{merchstore.name}</title>
      </Helmet>

      {searchTerm ||
      productFilterChosen !== 'All' ||
      brandFilterChosen !== 'All' ||
      priceFilterChosen !== DEFAULT_PRICE_RANGE ||
      currentPage > 0 ? null : (
        <CollectionPageHeader merchstore={merchstore} />
      )}

      <CollectionPageSortFilter
        productFilters={productFilters}
        brandFilters={brandFilters}
        allProducts={allProducts}
        setSortChosen={setSortChosen}
        setProductFilterChosen={setProductFilterChosen}
        setPriceFilterChosen={setPriceFilterChosen}
        setBrandFilterChosen={setBrandFilterChosen}
      />

      <CollectionPageProductTable
        sortChosen={sortChosen}
        isTableLoading={isTableLoading}
        productFilterChosen={productFilterChosen}
        brandFilterChosen={brandFilterChosen}
        priceFilterChosen={priceFilterChosen}
        filteredProducts={filteredProducts}
        allProducts={allProducts}
        onPageChange={(page: number) => setCurrentPage(page)}
      />
    </>
  );
};
