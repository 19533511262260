import { BigNumber } from 'bignumber.js';

import {
  ICart,
  IMerchStore,
  IStoreState,
} from '@merchstores/frontend/reduxTypes';
import { shallowEqual, useSelector } from 'react-redux';

import { moneyFormat } from '@merchstores/frontend/components/Money';
import {
  calculateSubtotalPrice,
  calculateSubtotalWithoutSavings,
} from '@merchstores/frontend/components/Cart/CartCalculator';
import CartGiftCard from './CartGiftCard';
import { CartContext } from '@merchstores/frontend/context/Cart';
import { useContext } from 'react';

export interface ICartSummaryProps {
  display?: boolean;
  storeCode: string;
}

export const CartSummary: React.FC<ICartSummaryProps> = (
  props: ICartSummaryProps
) => {
  const cart: ICart = useSelector(
    (state: IStoreState) => state.cart,
    shallowEqual
  );
  const merchstore: IMerchStore = useSelector(
    (state: IStoreState) => state.merchstore,
    shallowEqual
  );
  const {
    state: { member },
  } = useContext(CartContext);

  const itemCount = cart.itemCount || 0;

  const subtotalWithoutSavings: BigNumber = calculateSubtotalWithoutSavings(
    cart.items,
    cart.products
  );
  const subtotalWithSavings: BigNumber = calculateSubtotalPrice(
    cart.items,
    cart.products
  );
  const totalSavingsValue: BigNumber =
    subtotalWithoutSavings.minus(subtotalWithSavings);

  const hasSavings = totalSavingsValue.isGreaterThan(new BigNumber(0));

  const subtotal = moneyFormat(subtotalWithoutSavings);
  const totalSavings = moneyFormat(totalSavingsValue);

  const calculatedTotal =
    Number(subtotalWithSavings) - (member?.giftCard?.balance || 0);
  const cartTotal = moneyFormat(calculatedTotal > 0 ? calculatedTotal : 0);

  if (props.display === false) {
    return null;
  }

  return (
    <div className="cart-summary w-full">
      <p className="mt-5">
        <span className="font-bold text-gray-500">Subtotal:</span>
        <span className="text-gray-400 ml-2">
          {itemCount} {itemCount > 1 ? 'Items' : 'Item'}
        </span>
        <span className="float-right text-gray-900">{subtotal}</span>
      </p>
      {merchstore?.giftCardsStatus?.enabled && (
        <CartGiftCard storeCode={props?.storeCode} subTotal={subtotal} />
      )}
      {hasSavings && (
        <p className="mt-5">
          <span className="font-bold text-gray-500">Total Savings:</span>
          <span className="float-right text-gray-900">{totalSavings}</span>
        </p>
      )}

      <hr className="border-solid my-4" />

      <p className="mb-8 text-gray-900 font-bold">
        <span className="align-bottom	leading-8">Cart Total:</span>
        <span className="float-right text-gray-900 text-xl">{cartTotal}</span>
      </p>
    </div>
  );
};
