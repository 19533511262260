export const STORE_LOGO_SMALL_WIDTH = 350;

export const logoImageResizeByWidth = (
  cloudinaryAssetUrl: string,
  newLogoWidthPx: number
) => {
  return cloudinaryAssetUrl.replace(
    /(\/image\/upload)/gi,
    `/image/upload/w_${newLogoWidthPx}`
  );
};
