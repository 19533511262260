import { ICtaProps } from './index';
import './styles.scss';

export const CTA = (props: ICtaProps) => {
  const getClasses = () => {
    let styles = 'flex rounded-md font-bold items-center justify-center';
    if (props.disabled) {
      styles += ` opacity-50 bg-merch-disabled text-merch-disabled-text`;
    } else {
      switch (props.type.toLowerCase()) {
        case 'primary':
          styles += ` bg-merch-orange text-white text-14 shadow-sm`;
          break;
        case 'secondary':
          styles += ` bg-white text-merch-dark-gray border border-gray-200 text-12 hover:bg-gray-50 shadow-sm`;
          break;
        case 'secondary-gray':
          styles += ` bg-white text-merch-text-disabled border border-gray-200 text-12 hover:bg-gray-50 shadow-sm`;
          break;
        case 'tertiary':
          styles += ` bg-white text-merch-orange border border-gray-200 text-12 md:text-14 shadow-sm`;
          break;
        case 'icon':
          styles += ` bg-merch-light-gray shadow-sm`;
          break;
        case 'clear-background':
          styles += ` opacity-50 text-12 md:text-14`;
          break;
        case 'merchstore-primary':
          styles += ` cta-custom bg-merch-yellow text-white text-14 shadow-sm`;
          break;
        case 'mobile-icon':
          styles += ` bg-white text-merch-dark-gray border border-gray-200 text-12 hover:bg-gray-50 shadow-sm h-36 w-36 ${props.classes === 'force-mobile' ? '' : 'lg:h-auto lg:w-auto lg:py-3 lg:px-3'}`;
          break;
        case 'verified':
          styles += ` bg-green-500 text-white text-14 shadow-sm`;
          break;
        case 'flat':
          styles += ` text-merch-orange text-12 md:text-14`;
          break;
        case 'clean':
          styles += `bg-white text-merch-text-disabled justify-center`;
          break;
        default:
          break;
      }
    }
    switch (props.size.toLowerCase()) {
      case 'small':
        if (props.type.toLowerCase() !== 'mobile-icon') {
          styles += ` p-2 text-sm`;
        }
        break;
      case 'standard':
        styles += ` px-2.5  ${props.icon && !props.children ? 'py-2.5' : 'min-w-standard-button py-2'}`;
        break;
      case 'large':
        styles += ` w-10/12 px-3.5 py-1 md:py-2.5 ${props.icon && !props.children ? '' : 'min-w-standard-button'}`;
    }
    return styles;
  };
  return (
    <button
      disabled={props.disabled}
      type={props.formSubmit ? 'submit' : 'button'}
      className={`${props.mobileIconView ? 'mobile-icon' : ''} ${props.stretch ? 'w-full' : ''} ${getClasses()} ${props.classes ? props.classes : ''}`}
      onClick={props.onClick}
    >
      <span>{props.children}</span>
      <span
        className={`btn-icon  ${props.children && props.icon ? 'ml-2.5' : ''}`}
      >
        {props.icon}
      </span>
    </button>
  );
};
