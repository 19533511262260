const ACCENT_COLOR_STYLE_ELEMENT_ID = 'style-store-accent-color';

function isValidCssColor(cssColor: string): boolean {
  return CSS.supports('color', cssColor);
}

export function applyAccentColor(accentColor: string): boolean {
  if (document.getElementById(ACCENT_COLOR_STYLE_ELEMENT_ID)) {
    // Accent already loaded
    return true;
  }

  if (!isValidCssColor(accentColor)) {
    return false;
  }

  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  head.appendChild(style);

  const css = `
    button.cta-custom { 
      background-color: ${accentColor} !important; 
    }
    a.store-link:hover {
      color: ${accentColor} !important; 
    }
    .toast-success-notification {
      background-color: ${accentColor} !important; 
    }
    .cart-indicator-icon {
      background-color: ${accentColor} !important; 
    }
    .select-done-icon-container svg{
      color: ${accentColor} !important; 
    }
    .filter-select-button svg{
      color: ${accentColor} !important; 
    }
    .nav-text-active {
      color: ${accentColor} !important; 
    }
    .nav-text:hover {
      color: ${accentColor} !important; 
    }
    .store-footer-link:hover {
      color: ${accentColor} !important; 
    }
  `;
  style.id = ACCENT_COLOR_STYLE_ELEMENT_ID;
  style.appendChild(document.createTextNode(css));

  return true;
}
