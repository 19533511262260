import { useState, useEffect } from 'react';
import { isEmpty, isNil } from 'lodash';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import toast from '../../elements/Toast/toast';
import { Dispatch } from 'redux';
import { useHistory, useParams } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { IProductPageProps, IProductsParamsProps } from './index';
import './styles.scss';

import { Card } from '@merchstores/shared/elements/Card';
import { Gallery } from '@merchstores/shared/elements/Gallery';
import {
  FormSelect,
  IFormOptionProps,
} from '@merchstores/shared/elements/FormSelect';
import { FormInputNumber } from '@merchstores/shared/elements/FormInputNumber';
import { CTA } from '@merchstores/shared/elements/Cta';
import { getLocalDateText } from '../DateTime';
import { Loading } from '@merchstores/shared/components/Loading';
import {
  getCloudinary,
  getPublicIdFromUploadedImage,
} from '@merchstores/shared/components/Cloudinary';
import {
  generateLargeCloudinaryMockupUrl,
  getImageInformation,
} from '@merchstores/shared/components/Cloudinary/LogoMockup';
import { addToCart } from '../../actions/cart';
import {
  ICart,
  ICartItem,
  IMerchStore,
  IProductVariant,
  IStoreState,
  IUser,
} from '../../reduxTypes';
import { useRef } from 'react';
import Scroll from 'react-scroll';
import { skuWithoutVariantIdTag } from './InventoryProduct';

import {
  applyProductVariantQuantities,
  applyOutOfStockOnVariantQuantities,
} from '@merchstores/frontend/components/ProductPage/InventoryProduct';

import { moneyFormat } from '@merchstores/frontend/components/Money';

export const ProductPage = (props: IProductPageProps) => {
  // Define the actual props needed in the index file
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  const scroll = Scroll.animateScroll;
  const cloudinary = getCloudinary();

  const { productHandle } = useParams<IProductsParamsProps>();

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const [expandArtwork, setExpandArtwork] = useState(false);

  const [selectedVariant, setSelectedVariant] = useState({
    id: '',
    quantityAvailable: 0,
  });

  const [product, setProduct] = useState(props.product);
  const [description, setDescription] = useState('');

  const merchstore: IMerchStore = useSelector(
    (state: IStoreState) => state.merchstore,
    shallowEqual
  );
  const isMerchStoreClosed = merchstore.status === 'closed';
  const isMerchStoreInDraft = merchstore.status === 'draft';
  const isMerchStoreActive = merchstore.status === 'active';
  const cart: ICart = useSelector(
    (state: IStoreState) => state.cart,
    shallowEqual
  );
  const user: IUser = useSelector(
    (state: IStoreState) => state.user,
    shallowEqual
  );
  const isUserLoggedIn = user.loggedIn || !user.passwordRequired;

  const [shortendedDescription, setShortenedDescription] = useState('');
  const [decorationTypes, setDecorationTypes] = useState([]);
  const [decorationTypeSelected, setDecorationTypeSelected] =
    useState(undefined);
  const [decorationLocations, setDecorationLocations] = useState([]);
  const [decorationLocationSelected, setDecorationLocationSelected] =
    useState(undefined);
  const [artworkOptions, setArtworkOptions] = useState([]);
  const [artworkThumbnails, setArtworkThumbnails] = useState([]);
  const [selectedArtworkIndex, setSelectedArtworkIndex] = useState(null);
  const [logoMockup, setLogoMockup] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [selectedDecorationType, setSelectedDecorationType] = useState('');
  const [selectedDecorationLocation, setSelectedDecorationLocation] =
    useState('');
  const [productImages, setProductImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSoldOut, setIsSoldOut] = useState(true);

  const productInfoRef = useRef(null);
  const selectedArtwork =
    !isNil(selectedArtworkIndex) && !isEmpty(artworkOptions)
      ? artworkOptions[selectedArtworkIndex]
      : '';
  const selectedArtworkThumbnail =
    !isNil(selectedArtworkIndex) && !isEmpty(artworkThumbnails)
      ? artworkThumbnails[selectedArtworkIndex]?.value
      : '';

  useEffect(() => {
    if (!props.product) {
      return false;
    }

    if (props.product) {
      setProduct(props.product);

      const product = props.product;

      if (product.descriptionHtml) {
        const descriptionWithoutDetails = product.descriptionHtml.replace(
          /<details[^]*<\/details>/gi,
          ''
        );
        // if there is a <p> tag in the descriptionHtml, remove it. If not, just use descriptionWithoutDetails
        const descriptionWithoutPTag = descriptionWithoutDetails.search('<p>')
          ? descriptionWithoutDetails.replace(/<p[^]*<\/p>/gi, '')
          : descriptionWithoutDetails;
        setDescription(descriptionWithoutPTag);
      }
      if (product.description) {
        product.description.length > 150
          ? setShortenedDescription(
              product.description.substring(0, 150) + '...'
            )
          : setShortenedDescription(product.description);
      }

      if (product.tags) {
        const decorationTypes = product.tags.filter((t: string) =>
          t.startsWith('deco_type=')
        );
        setDecorationTypes(
          decorationTypes.map((d: string) => {
            return { displayText: d.split('=')[1], value: d.split('=')[1] };
          })
        );

        const decorationLocations = product.tags.filter((t: string) =>
          t.startsWith('deco_location=')
        );
        setDecorationLocations(
          decorationLocations.map((d: string) => {
            return { displayText: d.split('=')[1], value: d.split('=')[1] };
          })
        );

        const decorationType = product.tags.find((t: string) =>
          t.startsWith('selected_deco_type=')
        );
        if (decorationType) {
          setDecorationTypeSelected(decorationType.split('=')[1]);
        }

        const decorationLocation = product.tags.find((t: string) =>
          t.startsWith('selected_deco_location=')
        );
        if (decorationLocation) {
          setDecorationLocationSelected(decorationLocation.split('=')[1]);
        }
      }

      if (product.variants && product.variants.length > 0) {
        // Initial Out Of Stock Threshold before inventory sync
        product.variants = applyOutOfStockOnVariantQuantities(
          product.variants,
          product
        );

        applyProductVariantQuantities(product).then((variants) => {
          // Re-apply Out Of Stock Threshold after inventory sync
          product.variants = applyOutOfStockOnVariantQuantities(
            variants,
            product
          );
          setSelectedVariant(product.variants[0]);
        });
      }
      setLoading(false);
    } else {
      console.log(`No product found with handle ${productHandle}`);
      history.push('/404');
    }
  }, [history, productHandle, props.product]);

  useEffect(() => {
    if (product.id !== '' && merchstore) {
      if (product.tags) {
        const artworkOption = product.tags.find((t: string) =>
          t.includes('artwork_option=')
        );
        const artworkIndex = artworkOption
          ? parseInt(artworkOption.split('=')[1])
          : 1;
        const merchstoreArtworks = merchstore.artworkOptions;
        if (merchstoreArtworks) {
          const transformedArtworkThumbnails =
            transformArtworks(merchstoreArtworks);
          setArtworkThumbnails(transformedArtworkThumbnails);
          setSelectedArtworkIndex(artworkIndex - 1);
          setArtworkOptions(merchstoreArtworks);

          const productTags = product.tags.map((t: string) => t);

          getImageInformation(merchstoreArtworks[artworkIndex - 1])
            .catch((err) => {
              console.error('getImageInformation', err);
              return { width: undefined, height: undefined };
            })
            .then((logoImg) => {
              const mockupUrl = generateLargeCloudinaryMockupUrl(
                productTags,
                merchstoreArtworks[artworkIndex - 1],
                product.images[0].src,
                'merchstores',
                logoImg.width,
                logoImg.height
              );

              setLogoMockup(mockupUrl);
              const productImageArr = product.images.map(
                (i: { src: string }) => {
                  return i.src;
                }
              );
              productImageArr[0] = mockupUrl;
              setProductImages(productImageArr);
            });
        }

        const storeCodeLc = String(merchstore.storeCode).toLowerCase();
        const storeCodeTagLc = `merchstore_code=${storeCodeLc}`;
        const productTagsLc = product.tags.map((tag: string) =>
          String(tag).toLowerCase()
        );
        const isProductFromMerchstore = productTagsLc.includes(storeCodeTagLc);

        if (!isProductFromMerchstore) {
          // The product is from a different store, redirect to 404
          console.log('The product is from a different store, redirect to 404');
          history.push('/404');
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, product, merchstore]);

  useEffect(() => {
    if (product) {
      for (const variant of product.variants) {
        if (variant.quantityAvailable > 0) {
          setIsSoldOut(false);
        }
      }
    }
  }, [product]);

  const expandFunction = () => {
    setExpandArtwork(true);
  };

  const closeExpandFunction = () => {
    setExpandArtwork(false);
  };

  const transformArtworks = (artworkThumbnails: Array<string>) => {
    return artworkThumbnails.flatMap((artworkThumbnail: string) => {
      if (artworkThumbnail) {
        const splitted = artworkThumbnail.split('/');
        const fileName = splitted[splitted.length - 1];
        const publicId = getPublicIdFromUploadedImage(artworkThumbnail);
        const transformedArtworkThumbnailUrl = cloudinary.url(publicId, {
          width: 28,
          height: 22,
          crop: 'fit',
        });
        return {
          value: transformedArtworkThumbnailUrl,
          displayText: fileName,
        };
      }
      return [];
    });
  };

  const addProductToCart = () => {
    if (!isUserLoggedIn) {
      return toast.error(
        `Unable to add. You need to login before adding items to cart.`
      );
    }

    if (isMerchStoreClosed || isMerchStoreInDraft) {
      return toast.error(`Unable to add. The store is not active.`);
    }

    if (selectedVariant.quantityAvailable === 0) {
      return toast.error(
        `Unable to add. ${product.title} in ${selectedVariant.title} is out of stock.`
      );
    }

    const cartItem: ICartItem = {
      id: selectedVariant.id,
      price: selectedVariant.price ? selectedVariant.price : 0,
      productTitle: product.title,
      quantity: selectedQuantity,
      artwork: selectedArtwork,
      logoMockup: logoMockup,
      handle: product.handle,
      size: selectedVariant.title,
      decorationType: selectedDecorationType,
      decorationLocation: selectedDecorationLocation,
    };
    // Don't allow user to add items to cart if the amount would exceed the amount available
    for (const itemInCart of cart.items) {
      if (
        itemInCart.productTitle === cartItem.productTitle &&
        itemInCart.size === cartItem.size
      ) {
        const variants = product.variants;
        for (const variant of variants) {
          if (variant.title === cartItem.size) {
            if (
              cartItem.quantity + itemInCart.quantity >
              variant.quantityAvailable
            ) {
              return toast.error(
                `Unable to add. ${itemInCart.quantity} already in cart. Only ${variant.quantityAvailable} available.`
              );
            }
          }
        }
      }
    }
    dispatch(addToCart(cartItem));
    toast.success(`(${selectedQuantity}) ${product.title} added to cart!`, {
      // redirect to cart
      onClose: () => {
        history.push('/cart');
      },
    });
  };

  const onQuantityChange = (newValue: number) => {
    if (newValue > 1) {
      if (newValue <= selectedVariant.quantityAvailable) {
        setSelectedQuantity(newValue);
      } else {
        toast.error(
          `Only ${selectedVariant.quantityAvailable} available in ${selectedVariant.title}`
        );
        setSelectedQuantity(selectedVariant.quantityAvailable);
      }
    } else {
      setSelectedQuantity(1);
    }
  };

  const scrollToProductDetails = () => {
    if (productInfoRef && productInfoRef.current) {
      const { y } = productInfoRef.current.getBoundingClientRect();
      scroll.scrollTo(y, {
        smooth: true,
      });
    }
  };

  if (!product) {
    return <></>;
  }

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content={
            description.length > 150
              ? description.substring(0, 150) + '...'
              : description
          }
        />
        <title>{product.title}</title>
      </Helmet>
      <Loading isLoading={loading as boolean} />
      <div className="relative flex items-center flex-col w-full flex-0">
        <div
          className={`pdp-details-container ${expandArtwork ? 'expanded' : ''} w-full`}
        >
          <Card>
            <div className={`pdp-details flex flex-col md:flex-row`}>
              <div className={`pdp-artwork ${expandArtwork ? 'expanded' : ''}`}>
                {productImages && (
                  <Gallery
                    images={productImages}
                    hasThumbnails={true}
                    expandFunction={expandFunction}
                    closeExpandFunction={closeExpandFunction}
                  />
                )}
              </div>
              <div className="pdp-options flex flex-col bg-merch-light-gray bg-opacity-40 rounded-r-3xl w-full">
                <h1 className="font-bold text-2xl">{product.title}</h1>
                <p className="text-merch-text-disabled mt-5px mb-13">
                  {selectedVariant && selectedVariant.sku
                    ? skuWithoutVariantIdTag(selectedVariant.sku)
                    : ''}
                </p>
                <div className="flex">
                  {selectedVariant.price && (
                    <p className="text-18 font-bold mb-26">
                      {moneyFormat(selectedVariant.price.amount)}
                    </p>
                  )}
                  {selectedVariant.compareAtPrice &&
                    selectedVariant.price &&
                    selectedVariant.compareAtPrice > selectedVariant.price && (
                      <p className="text-18 mb-26 line-through opacity-50 ml-8px">
                        {moneyFormat(selectedVariant.compareAtPrice.amount)}
                      </p>
                    )}
                </div>
                <div className="form-row">
                  <label className="font-bold">Description:</label>
                  <p className="text-length-150">{shortendedDescription}</p>
                  <a
                    href="javascript:void();"
                    onClick={scrollToProductDetails}
                    className="store-link block text-base text-merch-dark-gray font-medium underline mt-2"
                  >
                    More Details
                  </a>
                </div>
                {product.variants && product.variants.length > 0 && (
                  <div className="mb-2">
                    <FormSelect
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      name="sizes"
                      label="Size"
                      options={product.variants.map(
                        (option: IProductVariant) => {
                          return {
                            value: option.id,
                            displayText: option.title ? option.title : '',
                            disabled:
                              option.quantityAvailable === 0 ? true : false,
                          };
                        }
                      )}
                      default={product.variants[0].id}
                      onChange={(selectedOption: IFormOptionProps) => {
                        if (product.variants) {
                          const selectedVariant = product.variants.find(
                            (v: IProductVariant) =>
                              v.id === selectedOption.value
                          );
                          if (selectedVariant) {
                            setSelectedVariant(selectedVariant);
                          }
                        }
                      }}
                      disabled={isMerchStoreClosed}
                    />
                  </div>
                )}
                {!isEmpty(artworkThumbnails) && (
                  <div className="mb-2">
                    <FormSelect
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      name="artwork"
                      label="Artwork"
                      options={[artworkThumbnails[selectedArtworkIndex]]} // supports if in the future artwork is editable
                      artwork={true}
                      default={selectedArtworkThumbnail}
                      disabled={isMerchStoreClosed}
                    />
                  </div>
                )}
                {decorationTypes.length > 0 && (
                  <div className="mb-2">
                    <FormSelect
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      name="decorationType"
                      label="Decoration Type"
                      options={
                        decorationTypeSelected
                          ? [
                              {
                                displayText: decorationTypeSelected,
                                value: decorationTypeSelected,
                              },
                            ]
                          : [decorationTypes[0]]
                      }
                      default={
                        decorationTypeSelected
                          ? decorationTypeSelected
                          : decorationTypes[0].value
                      }
                      onChange={(selectedOption: IFormOptionProps) => {
                        setSelectedDecorationType(selectedOption.value);
                      }}
                      disabled={isMerchStoreClosed}
                    />
                  </div>
                )}
                {decorationLocations.length > 0 && (
                  <div className="mb-2">
                    <FormSelect
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      name="decorationLocation"
                      label="Decoration Location"
                      options={
                        decorationLocationSelected
                          ? [
                              {
                                displayText: decorationLocationSelected,
                                value: decorationLocationSelected,
                              },
                            ]
                          : decorationLocations.length > 0
                            ? [decorationLocations[0]]
                            : [{ displayText: '', value: '' }]
                      }
                      default={
                        decorationLocationSelected
                          ? decorationLocationSelected
                          : decorationLocations[0].value
                      }
                      onChange={(selectedOption: IFormOptionProps) => {
                        setSelectedDecorationLocation(selectedOption.value);
                      }}
                      disabled={isMerchStoreClosed}
                    />
                  </div>
                )}
                <div className="flex w-full">
                  <CTA
                    disabled={
                      isSoldOut ||
                      isMerchStoreClosed ||
                      isMerchStoreInDraft ||
                      !isUserLoggedIn
                    }
                    type="merchstore-primary"
                    size="standard"
                    classes="text-sm flex-grow-2"
                    onClick={addProductToCart}
                  >
                    Add to Cart
                  </CTA>
                  <div className={`ml-2`}>
                    <FormInputNumber
                      name="quantity"
                      required={true}
                      register={register}
                      errors={errors}
                      defaultValue={selectedQuantity}
                      onChange={onQuantityChange}
                      positiveOnly={true}
                      integerOnly={true}
                      maxAllowed={selectedVariant.quantityAvailable}
                      disabled={isMerchStoreClosed}
                    />
                  </div>
                </div>
                {merchstore && (
                  <div className="flex flex-col items-center mt-5">
                    {!isUserLoggedIn && (
                      <p className="text-red-700">
                        This store requires a password. Please login before
                        adding to cart.
                      </p>
                    )}
                    {isMerchStoreActive && (
                      <p>Your order will be processed on:</p>
                    )}
                    {isMerchStoreClosed && (
                      <p className="text-red-700">This store closed on:</p>
                    )}
                    {merchstore.nextProcessingDate && !isMerchStoreInDraft && (
                      <p
                        className={`text-lg font-bold ${isMerchStoreClosed ? 'text-red-700' : ''}`}
                      >
                        {getLocalDateText(merchstore.nextProcessingDate)}
                      </p>
                    )}

                    {isMerchStoreInDraft && (
                      <p className="text-red-700">
                        This store is not yet active. Please contact the store
                        admin for additional details.
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
        <div ref={productInfoRef}></div>
      </div>
    </div>
  );
};
