import './styles.scss';

export const Card = (props: any) => {
  // Define the actual props needed in the index file
  return (
    <>
      <div className="card-wrapper max-w-7xl" {...props}>
        {props.children}
      </div>
    </>
  );
};
