import { resolveMerchStoreApiUrl } from '../api/merchstore';
import * as types from '../constants/ActionTypes';
import axios from 'axios';

/* * * Store * * */
export const fetchStore = (subdomain: string) => (dispatch: any) => {
  dispatch({
    type: types.FETCH_STORE_INFO_START,
  });

  axios
    .post(
      resolveMerchStoreApiUrl(
        '/.netlify/functions/merchstore-api/fetchMerchStoreBySubdomain'
      ),
      { subdomain }
    )
    .then((res: any) => {
      //TODO view response
      const merchstore = res.data;
      dispatch({
        type: types.FETCH_STORE_INFO_END,
        merchstore,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: types.FETCH_STORE_INFO_ERROR,
      });
    });
};
