export interface ICartAdminPayerLabelProps {
  display?: boolean;
}

export const CartAdminPayerLabel: React.FC<ICartAdminPayerLabelProps> = (
  props: ICartAdminPayerLabelProps
) => {
  if (!props.display) {
    return null;
  }

  return (
    <div className="cart-admin-payer-label my-3">
      Payment will be handled by MerchStore admin
    </div>
  );
};
