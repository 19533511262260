import { toast } from 'react-toastify';

export default {
  // Keep the signature of the original toast object
  // Doing so you can pass additionnal options
  success(msg, options = {}) {
    return toast.success(msg, {
      // Merge additionals options
      ...options,
      className: 'toast-success-notification',
    });
  },
  error(msg, options = {}) {
    return toast.error(msg, {
      ...options,
    });
  },
};
