import { useState } from 'react';
import IGalleryProps from '.';
import { Thumbnail } from './Thumbnail';
import './styles.scss';
import { useEffect } from 'react';
import { MdFullscreen } from 'react-icons/md';
import { MdClose } from 'react-icons/md';

export const Gallery = ({
  images,
  hasThumbnails,
  selectedPackage,
  currentImage,
  onChange,
  expandFunction,
  closeExpandFunction,
}: IGalleryProps) => {
  const [selectedImage, setSelectedImage] = useState('');
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (currentImage) {
      setSelectedImage(currentImage);
    } else if (!selectedImage) {
      setSelectedImage(images[0]);
    }
  }, [currentImage, images]);

  useEffect(() => {
    // When a new package is selected, change the selectedImage
    setSelectedImage(images[0]);
  }, [selectedPackage]);

  const selectImage = (imageUrl: URL) => {
    setSelectedImage(imageUrl);
    if (onChange) {
      onChange(imageUrl);
    }
  };

  const onClickExpand = () => {
    if (expandFunction) {
      expandFunction();
    }
    setExpanded(true);
  };

  const onClickCloseExpand = () => {
    if (closeExpandFunction) {
      closeExpandFunction();
    }
    setExpanded(false);
  };

  return (
    <>
      <div className={`gallery-container ${expanded ? 'expanded' : ''}`}>
        <div className={`main-image ${hasThumbnails ? 'has-thumbnails' : ''}`}>
          {expandFunction && !expanded && (
            <div className="full-screen-icon" onClick={onClickExpand}>
              <MdFullscreen />
            </div>
          )}
          {closeExpandFunction && expanded && (
            <div className="full-screen-icon" onClick={onClickCloseExpand}>
              <MdClose />
            </div>
          )}
          <img
            src={selectedImage}
            alt="Product"
            onClick={expanded ? onClickCloseExpand : onClickExpand}
          />
        </div>
        {hasThumbnails === true && !expanded && (
          <div className="thumbnail-container">
            {images.map((image, index) => {
              return (
                <Thumbnail
                  key={`thumbnail_${index}`}
                  name={`Thumbnail ${index + 1}`}
                  imageUrl={image}
                  onClick={() => selectImage(image)}
                  selected={image === selectedImage}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
