import { HiShoppingCart } from 'react-icons/hi';
import { CTA } from '@merchstores/shared/elements/Cta';
import { useHistory } from 'react-router-dom';
import { ICart, IStoreState, IUser } from '@merchstores/frontend/reduxTypes';
import { shallowEqual, useSelector } from 'react-redux';
import './styles.scss';
export interface ICartIconProps {
  display?: boolean;
  classes?: string;
}

export const CartIcon: React.FC<ICartIconProps> = (props: ICartIconProps) => {
  const cart: ICart = useSelector(
    (state: IStoreState) => state.cart,
    shallowEqual
  );
  const user: IUser = useSelector(
    (state: IStoreState) => state.user,
    shallowEqual
  );
  const itemsCount = cart.itemCount || 0;
  const showIndicator = itemsCount > 0;

  const history = useHistory();

  const handleCartClick = (): boolean => {
    if (user.loggedIn || !user.passwordRequired) {
      history.push('/cart');
      return true;
    }
    return false;
  };

  return (
    <div className={`cart-indicator ${props.classes ? props.classes : ''}`}>
      <div className="relative">
        {showIndicator ? (
          <div className="absolute left-6 top-0.5 cart-indicator-icon"></div>
        ) : (
          ''
        )}

        <CTA
          type={'secondary'}
          size={'standard'}
          icon={<HiShoppingCart size={16} />}
          onClick={handleCartClick}
        />
      </div>
    </div>
  );
};
