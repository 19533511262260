import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  IProductPageProps,
  IProductsParamsProps,
  ProductPage,
} from '../../components/ProductPage';
import { ProductPageHeader } from '../../components/ProductPageHeader';
import { ProductPageDetails } from '@merchstores/frontend/components/ProductPageDetails';
import { Loading } from '@merchstores/shared/components/Loading';
import { IMerchStore, IStoreState } from '@merchstores/frontend/reduxTypes';
import { shallowEqual, useSelector } from 'react-redux';
import { getProductDetails } from '@merchstores/frontend/api/products/MerchStoreProductDetails';

export const ProductPageLayout: React.FC<
  IProductPageProps
> = (/*props: IProductPageProps*/) => {
  const { productHandle } = useParams<IProductsParamsProps>();
  const merchstore: IMerchStore = useSelector(
    (state: IStoreState) => state.merchstore,
    shallowEqual
  );
  const history = useHistory();

  const [product, setProduct] = useState({ id: '', title: '', variants: [] });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!merchstore) {
      return false;
    }

    getProductDetails(productHandle).then((product) => {
      if (!product) {
        console.log(`No product found with handle ${productHandle}`);
        history.push('/404');
        return false;
      }

      const storeCodeLc = String(merchstore.storeCode).toLowerCase();
      const storeCodeTagLc = `merchstore_code=${storeCodeLc}`;
      const productTagsLc = product.tags.map((tag: string) =>
        String(tag).toLowerCase()
      );
      const isProductFromMerchstore = productTagsLc.includes(storeCodeTagLc);

      if (!isProductFromMerchstore) {
        // The product is from a different store, redirect to 404
        console.log('The product is from a different store, redirect to 404');
        history.push('/404');
        return false;
      }

      setProduct(product);
      setLoading(false);
    });
  }, [history, merchstore, productHandle]);

  if (loading) {
    return <Loading isLoading={true} />;
  }

  return (
    <>
      <ProductPageHeader product={product} />
      <ProductPage product={product} />
      <ProductPageDetails product={product} />
    </>
  );
};
