import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Loading } from '@merchstores/shared/components/Loading';
import {
  generateMediumCloudinaryMockupUrl,
  getImageInformation,
} from '@merchstores/shared/components/Cloudinary/LogoMockup';
import './styles.scss';
import { ICollectionPageProductsProps } from '.';
import { shallowEqual, useSelector } from 'react-redux';
import { IMerchStore, IStoreState, IUser } from '../../../reduxTypes';
import { ProductTile } from './ProductTile';

export function resolveArtworkOptionIndex(productTags: string[]): number {
  const artworkOption = productTags.find((t: string) =>
    t.includes('artwork_option=')
  );

  if (artworkOption) {
    const artworkOptionParts = artworkOption.split('=');

    if (artworkOptionParts.length > 1) {
      return parseInt(artworkOptionParts[1]) - 1;
    }
  }

  return 0;
}

export const CollectionPageProductTable = (
  props: ICollectionPageProductsProps
) => {
  const history = useHistory();

  const {
    allProducts,
    filteredProducts,
    productFilterChosen,
    brandFilterChosen,
    priceFilterChosen,
    sortChosen,
    onPageChange,
  } = props;

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [footerText, setFooterText] = useState('');
  const [displayProducts, setDisplayProducts] = useState([]);
  const [artworkOptions, setArtworkOptions] = useState([]);
  const [, /*isMobile*/ setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [, /*imgSize*/ setImgSize] = useState('large');

  const merchstore: IMerchStore = useSelector(
    (state: IStoreState) => state.merchstore,
    shallowEqual
  );
  const user: IUser = useSelector(
    (state: IStoreState) => state.user,
    shallowEqual
  );

  useEffect(() => {
    const isMobile = window.matchMedia(
      'only screen and (max-width: 760px)'
    ).matches;
    const isTablet = window.matchMedia(
      'only screen and (max-width: 1024px)'
    ).matches;
    if (isMobile) {
      setImgSize('compact');
    }
    setIsMobile(isMobile);
    setIsTablet(isTablet);
  }, []);

  useEffect(() => {
    setPage(0);
  }, [sortChosen]);

  useEffect(() => {
    if (
      productFilterChosen !== 'All' ||
      brandFilterChosen !== 'All' ||
      priceFilterChosen
    ) {
      setProducts(filteredProducts);
    } else {
      setProducts(allProducts);
    }
  }, [productFilterChosen, allProducts, filteredProducts]);

  useEffect(() => {
    let data = [];
    let count;
    isTablet ? (count = 20) : (count = 24);

    data = products.slice(currentPage * count, currentPage * count + count);
    if (
      products.slice(
        (currentPage + 1) * count,
        (currentPage + 1) * count + count
      ).length <= 0
    ) {
      setHasNextPage(false);
    } else {
      setHasNextPage(true);
    }

    // Moves the user to first page after a filter is chosen
    if (currentPage !== 0 && data.length === 0) {
      setPage(0);
    }

    if (currentPage === 0) {
      setHasPreviousPage(false);
    } else {
      setHasPreviousPage(true);
    }

    Promise.all(
      data.map(async (product: any) => {
        const imgSrc = product.images[0]?.src;
        const logoTag = product.tags.find((t: string) =>
          t.includes('LogoArea=')
        );
        const artworkOptionIndex = resolveArtworkOptionIndex(product.tags);
        const logoUrl = artworkOptions[artworkOptionIndex];

        const fallbackResult = {
          ...product,
          productLogoMockupUrl: imgSrc,
        };

        if (!logoUrl) {
          console.error(
            `Product ${product.id} - Missing artwork option / logoUrl`
          );
          return fallbackResult;
        }

        if (!logoTag) {
          console.error(`Product ${product.id} - Missing LogoArea tag`);
          return fallbackResult;
        }

        if (!imgSrc) {
          console.error(`Product ${product.id} - Missing Shopify image`);
          return fallbackResult;
        }

        let productLogoMockupUrl = generateMediumCloudinaryMockupUrl(
          [logoTag],
          logoUrl,
          imgSrc,
          'merchstores'
        );

        if (!productLogoMockupUrl) {
          return fallbackResult;
        }

        if (imgSrc?.indexOf('shopify') > -1 && logoTag) {
          const logoImageInformation = await getImageInformation(logoUrl).catch(
            () => null
          );

          if (logoImageInformation) {
            productLogoMockupUrl = generateMediumCloudinaryMockupUrl(
              [logoTag],
              logoUrl,
              imgSrc,
              'merchstores',
              logoImageInformation.width,
              logoImageInformation.height
            );
          }
        }

        return {
          ...product,
          productLogoMockupUrl,
        };
      })
    ).then((pageProducts) => {
      setDisplayProducts(pageProducts);
    });

    const startIndex = currentPage * count + 1;
    const endIndex = currentPage * count + data.length;
    setFooterText(
      `Showing ${data.length > 0 ? startIndex + ' - ' + endIndex : '0'} of ${products.length} items`
    );
    if (currentPage !== 0) {
      // Scroll to the pagination arrows
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [currentPage, products]);

  useEffect(() => {
    const merchstoreArtwork = merchstore.artworkOptions;
    if (merchstoreArtwork) {
      setArtworkOptions(merchstoreArtwork);
    }
  }, [merchstore]);

  const goToProduct = (handle: string) => {
    if (user.loggedIn || !user.passwordRequired) {
      history.push(`${handle}`);
    }
  };

  const setPage = (page: number) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  if (props.isTableLoading) {
    return (
      <div className="flex items-center justify-center lg:px-50">
        <div className="border-0 w-full lg:w-10/12 min-h-collection-table">
          <Loading isLoading={props.isTableLoading as boolean} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center lg:px-50">
      <div className="border-0 w-full lg:w-10/12 min-h-collection-table">
        {filteredProducts && filteredProducts.length === 0 ? (
          <div className="flex content-center items-center justify-center h-200">
            <div className="italic text-2xl">No Products</div>
          </div>
        ) : (
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 rounded-t-2xl overflow-hidden">
            {displayProducts &&
              displayProducts.map((product: any) => {
                return (
                  <ProductTile
                    key={product.id}
                    product={product}
                    onClick={() => goToProduct(product.handle)}
                  />
                );
              })}
          </div>
        )}
        <div className="flex justify-between flex-row footer-container h-full pl-7 pr-7 mt-4">
          <div className="flex justify-start pagination-count italic">
            {footerText}
          </div>
          {(hasPreviousPage || hasNextPage) && (
            <div className="pagination-arrows buttons-container group">
              <button
                className={`secondary ${!hasPreviousPage ? 'disabled' : ''} left`}
                disabled={!hasPreviousPage ? true : false}
                onClick={() => setPage(currentPage - 1)}
              >
                <MdKeyboardArrowLeft size="18px" />
              </button>
              <button
                className={`secondary ${!hasNextPage ? 'disabled' : ''} right`}
                disabled={!hasNextPage ? true : false}
                onClick={() => setPage(currentPage + 1)}
              >
                <MdKeyboardArrowRight size="18px" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
