import { useCallback } from 'react';
import { useState, useRef, useEffect } from 'react';
import { IconContext } from 'react-icons';
import {
  MdHelpOutline,
  MdErrorOutline,
  MdArrowDropDown,
  MdArrowDropUp,
  MdDone,
} from 'react-icons/md';
import { IFormSelectProps, IFormOptionProps } from './index';
import './styles.scss';
import { Tooltip } from '../Tooltip';

function useComponentVisible() {
  const [selectOpen, setSelectOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSelectOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, selectOpen, setSelectOpen };
}

export const FormSelect = (props: IFormSelectProps) => {
  const [selectedOption, setSelectedOption] = useState(undefined);
  const { ref, selectOpen, setSelectOpen } = useComponentVisible();
  const [triggerChangeOnDefault, setTriggerChangeOnDefault] = useState(
    props.triggerChangeOnDefault === false ? false : true
  );

  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevDefault = usePrevious(props.default);

  const handleOptionSelect = useCallback(
    (option: IFormOptionProps) => {
      props.setValue(props.name, option.value);
      setSelectedOption(option);
      setSelectOpen(false);
      if (props.onChange) {
        if (triggerChangeOnDefault || option.value !== props.default) {
          props.onChange(option);
        }
      }
    },
    [props, setSelectedOption, setSelectOpen]
  );

  useEffect(() => {
    if (props.default && props.default !== prevDefault) {
      const selected = props.options.filter(
        (option: IFormOptionProps) => option.value === props.default
      );
      if (selected.length > 0) {
        handleOptionSelect(selected[0]);
        props.setValue(props.name, props.default);
      }
    }
  }, [props, prevDefault, handleOptionSelect]);

  useEffect(() => {
    if (
      props.triggerChangeOnDefault !== undefined &&
      triggerChangeOnDefault !== props.triggerChangeOnDefault
    ) {
      setTriggerChangeOnDefault(props.triggerChangeOnDefault);
    }
  }, [props.triggerChangeOnDefault]);

  return (
    <>
      <div
        className={`flex flex-col ${props.disabled ? 'opacity-50' : ''} ${props.halfWidth ? 'w-1/2' : 'w-full'} ${props.selectStyle === 'two' ? '' : 'mb-5'} form-select-container ${props.classes}`}
      >
        {props.selectStyle !== 'two' && (
          <div className="flex justify-between mb-2">
            <div className="flex items-center">
              <label
                htmlFor={props.name}
                className="text-sm text-merch-dark-gray font-bold"
              >
                {props.label}
              </label>
              {props.tooltip && (
                <>
                  <Tooltip
                    content={props.tooltip}
                    place={props.tooltipPosition}
                  >
                    <div
                      className="opacity-50 ml-2"
                      data-tip={props.tooltip}
                      data-for={props.name}
                    >
                      <MdHelpOutline />
                    </div>
                  </Tooltip>
                </>
              )}
            </div>

            {props.helpText && (
              <div className="text-xs italic text-merch-dark-gray opacity-50 mr-3">
                {props.helpText}
              </div>
            )}
          </div>
        )}
        <input
          id={`${props.name}-hidden-select`}
          name={props.name}
          {...props.register(props.name)}
          className="hidden bg-right-top group-order-form-select p-3 border border-gray-300 rounded-md"
        ></input>
        {props.selectStyle === 'two' && (
          <div>
            {props.label && (
              <label htmlFor={props.name} className={`textbox-label`}>
                {props.label}
              </label>
            )}
          </div>
        )}
        <div
          className={`
        h-50 bg-white text-sm font-medium group-order-form-select py-3
        ${selectOpen ? '' : 'border border-gray-300'}
        rounded-md relative
        ${props.options.length === 1 && selectedOption ? 'one-option' : ''}
        ${props.name === 'reassignGiftCardSelectMember' ? 'height-fit-content' : ''}
      `}
        >
          <div>
            <div>
              <div
                className={`${props.name}-select-item px-3 w-full flex justify-between items-center ${props.disabled ? 'opacity-50' : ''}`}
                onClick={() => {
                  if (
                    !props.disabled &&
                    props.options &&
                    (props.options.length > 1 || !selectedOption)
                  ) {
                    setSelectOpen(true);
                  }
                }}
              >
                {props.artwork && selectedOption && (
                  <div className="h-7 w-7 bg-gray-50 border border-gray-200 rounded-md flex items-center justify-center">
                    <img src={selectedOption.value} alt="Selected Artwork" />
                  </div>
                )}
                <div
                  className={`${selectedOption ? '' : 'opacity-50'} ${props.selectStyle === 'two' ? 'mt-10' : ''}`}
                >
                  {selectedOption
                    ? selectedOption.displayText
                    : props.emptyText
                      ? props.emptyText
                      : props.selectStyle === 'two'
                        ? ''
                        : props.label}
                </div>
                <MdArrowDropDown />
              </div>
            </div>
            {props.errors[props.name] &&
              Object.keys(props.errors[props.name]).length > 0 && (
                <div className="flex items-center">
                  {props.errors[props.name]?.type === 'required' ? (
                    <span className="error">{props.label} is required</span>
                  ) : (
                    <span className="error">
                      {props.errors[props.name]?.message}
                    </span>
                  )}
                  <div className="mt-1 ml-1 opacity-50">
                    <MdErrorOutline />
                  </div>
                </div>
              )}
            <div
              ref={ref}
              className={`${props.name}-select-item text-sm font-medium z-10 absolute ${!selectOpen ? 'hidden' : ''} w-full top-0 left-0 bg-white group-order-form-select border border-gray-300 rounded-md overflow-auto`}
            >
              <div
                className="flex p-3 justify-between items-center"
                onClick={() => {
                  setSelectOpen(!setSelectOpen);
                }}
              >
                {props.artwork && selectedOption && (
                  <div className="h-7 w-7 bg-gray-50 border border-gray-200 rounded-md flex items-center justify-center">
                    <img src={selectedOption.value} alt="Selected Artwork" />
                  </div>
                )}
                <div className={`${selectedOption ? '' : 'opacity-50'}`}>
                  {selectedOption
                    ? selectedOption.displayText
                    : props.emptyText
                      ? props.emptyText
                      : props.label}
                </div>
                <MdArrowDropUp />
              </div>
              {props.options.map((option: any, index: number) => {
                return (
                  <div
                    className={`${props.name}-select-item flex justify-between items-center p-3 cursor-pointer hover:bg-merch-light-gray ${option?.disabled && 'text-merch-text-disabled'}`}
                    onClick={() => {
                      !option?.disabled && handleOptionSelect(option);
                    }}
                    key={`${props.name}-select-option-${index}`}
                  >
                    {option?.displayText}
                    {option?.disabled ? (
                      <div className="text-12">Out of stock</div>
                    ) : selectedOption ? (
                      option?.value === selectedOption.value ? (
                        <IconContext.Provider value={{ color: '#FF671D' }}>
                          <div className="select-done-icon-container">
                            <MdDone />
                          </div>
                        </IconContext.Provider>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
