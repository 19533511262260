import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IHeaderProps } from './index';
import { CartIcon } from '../Cart/CartIcon';
import './styles.scss';
import { IMerchStore, IStoreState } from '@merchstores/frontend/reduxTypes';
import { shallowEqual, useSelector } from 'react-redux';

export const Header = (props: IHeaderProps) => {
  // Define the actual props needed in the index file
  const [loading, setLoading] = useState(true);
  const [storeLogo, setStoreLogo] = useState('');
  const history = useHistory();
  const merchstore: IMerchStore = useSelector(
    (state: IStoreState) => state.merchstore,
    shallowEqual
  );

  const handleStoreLogoClick = (): boolean => {
    history.push('/');
    return true;
  };

  useEffect(() => {
    if (props.storeLogo) {
      setStoreLogo(props.storeLogo);
      setLoading(false);
    }
  }, [props.storeLogo]);

  return (
    <header className="storefront pl-4 pr-4 lg:px-11 flex justify-center">
      <div className="flex items-stretch justify-between w-full lg:w-10/12 pr-7">
        {loading ? (
          <img
            src={
              merchstore.storeLogo
                ? merchstore.storeLogo
                : 'https://cdn.shopify.com/s/files/1/0586/0448/6853/files/logo.svg?v=1646790589'
            }
            className="self-start"
            onClick={handleStoreLogoClick}
          />
        ) : (
          <img
            src={storeLogo}
            className="self-start"
            alt="Store logo"
            onClick={handleStoreLogoClick}
          />
        )}

        <CartIcon classes="self-end self-center" />
      </div>
    </header>
  );
};
