import { useState } from 'react';
import { Dispatch } from 'redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { MdClose } from 'react-icons/md';
import { ICartItem, ICartProduct } from '@merchstores/frontend/reduxTypes';
import { Logo } from '@merchstores/shared/elements/Logo';
import { Gallery } from '@merchstores/shared/elements/Gallery';
import { moneyFormat } from '@merchstores/frontend/components/Money';
import {
  handleRemoveItem,
  handleUpdateItem,
} from '@merchstores/frontend/components/Cart/CartActions';
import { countTotalItems } from '@merchstores/frontend/components/Cart/CartCount';
import { calculateSubtotalPrice } from '@merchstores/frontend/components/Cart/CartCalculator';
import { mapProductVariantsById } from '@merchstores/frontend/components/Cart/CartProducts';

import { FormInputNumber } from '@merchstores/shared/elements/FormInputNumber';
import './styles.scss';

export interface ICartProductListItemProps {
  product: ICartProduct;
  items: Array<ICartItem>;
}

export const CartProductListItem = (props: ICartProductListItemProps) => {
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const product: ICartProduct = props.product;
  const items: Array<ICartItem> = props.items;
  const style: ICartItem = items[0];

  const productVariants = mapProductVariantsById(product);

  const subtotalPrice = calculateSubtotalPrice(items, [product]);
  const totalItemCount = countTotalItems(items);

  const goToProductPage = (product: ICartProduct) => {
    history.push(`/${product.handle}`);
  };

  const removeItem = (item: ICartItem): void => {
    handleRemoveItem(dispatch, item);
  };

  const updateItem = (item: ICartItem): void => {
    handleUpdateItem(dispatch, item);
  };

  const [expandArtwork, setExpandArtwork] = useState(false);

  const expandFunction = () => {
    setExpandArtwork(true);
  };

  const closeExpandFunction = () => {
    setExpandArtwork(false);
  };

  const ItemLine = (itemLineProps: { item: ICartItem }) => {
    const item = itemLineProps.item;
    const defaultQuantity = item.quantity;
    const {
      register,
      /*setValue,*/ formState: { errors },
    } = useForm();
    const quantityInputName = `${item.handle}-${item.size}-quantity`;
    const productVariant = productVariants.get(item.id);

    const onQuantityChange = (newQuantity: number) => {
      const quantityAvailable = productVariant?.quantityAvailable || 0;

      if (newQuantity > quantityAvailable) {
        newQuantity = quantityAvailable;
        toast.error(
          `Only ${productVariant?.quantityAvailable} available in ${productVariant?.title}`
        );
      }

      item.quantity = newQuantity;
      updateItem(item);
    };

    return (
      <div>
        <div className="grid grid-cols-3 gap-4 mt-4">
          <div>
            <div>Size:</div>
            <span className="font-bold">{item.size}</span>
          </div>

          <div>
            <div>Price:</div>
            <span className="font-bold">
              {item.price && moneyFormat(item.price.amount)}
            </span>
          </div>

          <div className="flex flex-row justify-between">
            <div className="self-center flex-shrink">
              <FormInputNumber
                name={quantityInputName}
                required={true}
                register={register}
                errors={errors}
                defaultValue={defaultQuantity}
                onChange={onQuantityChange}
                positiveOnly={true}
                integerOnly={true}
                maxAllowed={productVariant?.quantityAvailable || 0}
              />
            </div>

            <div
              className="remove-item-btn self-center cursor-pointer"
              onClick={() => removeItem(item)}
            >
              <MdClose />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TotalQuantity = () => {
    return (
      <div className="w-14">
        <div className="text-center w-full">Qty</div>
        <div className="font-bold text-center">{totalItemCount}</div>
      </div>
    );
  };

  const TotalPrice = () => {
    return (
      <>
        <div>Total:</div>
        <span className="font-bold">{moneyFormat(subtotalPrice)}</span>
      </>
    );
  };

  return (
    <div
      className={`cart-product-list-item mb-12 flex ${expandArtwork ? 'flex-col' : 'flex-col lg:flex-row'}`}
    >
      <div
        className={`product-artwork ${expandArtwork ? 'w-full' : 'lg:w-1/6'} `}
      >
        <div className="border rounded-2xl border-gray-30 p-2 flex">
          {product.images && (
            <Gallery
              images={
                style.logoMockup
                  ? [style.logoMockup]
                  : product.images.map((i: any) => {
                      return i.src;
                    })
              }
              hasThumbnails={false}
              expandFunction={expandFunction}
              closeExpandFunction={closeExpandFunction}
            />
          )}
        </div>
      </div>

      <div className="mt-8 lg:px-8 lg:mt-0 lg:w-5/6 ">
        <h3
          className="font-bold text-2xl flex-col cursor-pointer"
          onClick={() => {
            goToProductPage(product);
          }}
        >
          {style.productTitle}
        </h3>

        <div className="grid grid-cols-3 gap-4 mt-4">
          <div>
            <div>Logo:</div>
            {style.artwork ? (
              <Logo size="small" imgUrl={style.artwork as string} />
            ) : (
              <span className="font-bold"> - </span>
            )}
          </div>

          <div>
            <div>Location:</div>
            <span className="font-bold">{style.decorationLocation || '-'}</span>
          </div>

          <div>
            <div>Decoration Type:</div>
            <span className="font-bold">{style.decorationType || '-'}</span>
          </div>
        </div>

        <hr className="border-solid mt-4 mb-2" />

        <div className="flex flex-col">
          {items.map((item) => {
            return <ItemLine key={`${item.handle}-${item.size}`} item={item} />;
          })}
        </div>

        <hr className="border-solid my-4" />

        <div className="grid grid-cols-3 gap-4 mt-4">
          <div className="col-start-2">
            <TotalPrice />
          </div>

          <div className="text-left">
            <TotalQuantity />
          </div>
        </div>
      </div>
    </div>
  );
};
